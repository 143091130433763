import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import styles from "./Main.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"
import { CaseStudiesBlock } from "./index.js"

// eslint-disable-next-line
export const CaseStudiesPage = ({ customers, casestudies, main, ...props }) => (
  <Layout {...props} {...casestudies}>
    <Container className={altStyles.wrapper}>
      <div>
        <Title  className={`${altStyles.title} ${altStyles.titlesmall}`} dangerouslySetInnerHTML={{ __html: casestudies.title }} />
        <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(casestudies.description || "") }} />
      </div>
      {casestudies.image ? (
        <div className={styles.bannerImage}>
          <img
            width="320"
            height="320"
            loading="lazy"
            objectFit="contain"
            alt={casestudies.title}
            title={casestudies.title}
            src={casestudies.image.publicURL}
          />
        </div>
      ) : null}
    </Container>

    <div className={styles.casestudiesustomer}>
      <CaseStudiesBlock data={main} customers={customers} noSlider noTitle />
    </div>
    {casestudies.form && casestudies.portalId ? (
      <div className={altStyles.regularBackground} id="contact-form">
        <Container>
        <div className={altStyles.titleContainer}>
            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: casestudies.formTitle || ""}} /></TitleBlock>
          </div>
          <HubspotForm id={casestudies.form} portalId={casestudies.portalId} />
        </Container>
      </div>
    ) : null}
  </Layout>
)

// eslint-disable-next-line
export const CaseStudiesPageWrapper = ({ data, ...props }) => (
  <CaseStudiesPage
    {...props}
    customers={data.customers.edges}
    casestudies={data.casestudies.frontmatter}
    main={data.main.frontmatter}
  />
)

export default CaseStudiesPageWrapper

export const pageQuery = graphql`
  query CaseStudiesPage {
    casestudies: markdownRemark(frontmatter: { templateKey: { eq: "casestudies" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        formTitle
        form
        portalId
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    main: markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
      frontmatter {
        mainCustomers {
          title
          list {
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 220, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    customers: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "successstories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            mainTitle
            date(formatString: "MMM Do, YYYY")
            quote
            logo {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
